import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="container footer-right" style={{}}>
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <h5 className="mb-3">Follow Us</h5>
            <div className="social-icons d-flex justify-content-center gap-3">
              <a href="https://www.facebook.com" target="_blank" className="text-white">
                <FontAwesomeIcon icon={faSquareFacebook} size="2x" style={{ margin: '10px' }} />
              </a>
              <a href="https://www.twitter.com" target="_blank" className="text-white">
                <FontAwesomeIcon icon={faTwitter} size="2x" style={{ margin: '10px' }} />
              </a>
              <a href="https://www.instagram.com" target="_blank" className="text-white">
                <FontAwesomeIcon icon={faInstagram} size="2x" style={{ margin: '10px' }} />
              </a>
              <a href="https://www.linkedin.com" target="_blank" className="text-white">
                <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ margin: '10px' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
