import './Header.css';
import Logo from '@features/auth/components/Header/Logo/Logo.tsx';
import Searchbar from '@features/auth/components/Header/Searchbar/Searchbar.tsx';
import LanguageSelector from '@features/auth/components/Header/LanguageSelector/LanguageSelector.tsx';
import { useNavigate } from 'react-router-dom';
import LocationFinder from '../../Location/LocationSelector.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login'); // Redirect to the LoginForm
  };
  const handlePostAnAd = () => {
    navigate('/PostAd');
  };
  return (
    <nav className="navbar-container">
      <div className="navbar-content" style={{ maxHeight: '40px' }}>
        <div className="navbar-left">
          <Logo altText="Logo" />
          <LocationFinder />
        </div>
        <div className="navbar-center">
          <Searchbar />
        </div>
        <div className="navbar-right">
          <LanguageSelector />
          <button
            className="btn bg-primary text-light"
            onClick={handleLoginRedirect}
            style={{ maxHeight: '40px' }}
          >
            Login
          </button>
          <button
            className="btn bg-primary text-light"
            onClick={handlePostAnAd}
            style={{ maxHeight: '40px' }}
          >
            PostAd
          </button>
          <FontAwesomeIcon icon={faCartShopping} size="2x" />
        </div>
      </div>
    </nav>
  );
};
export default Header;
