import { useState } from 'react';
import type { MenuItem } from './menuData';
import { menuData } from './menuData';
import './SidebarMenu.css';

const SidebarMenu = () => {
  const [currentMenu, setCurrentMenu] = useState<MenuItem[]>(menuData); // Current menu level
  const [breadcrumb, setBreadcrumb] = useState<string[]>([]); // Tracks navigation path

  const handleClick = (item: MenuItem) => {
    if (item.items) {
      // Navigate to subcategories
      setBreadcrumb([...breadcrumb, item.title]);
      setCurrentMenu(item.items);
    } else if (item.link) {
      // Navigate to category link
      window.location.href = item.link; // Replace with React Router navigate if using React Router
    }
  };

  const handleBack = () => {
    const newBreadcrumb = [...breadcrumb];
    newBreadcrumb.pop(); // Go one level back
    setBreadcrumb(newBreadcrumb);

    // Traverse menuData to find the corresponding menu level
    let menu: MenuItem[] = menuData;
    newBreadcrumb.forEach((crumb) => {
      const menuItem = menu.find((item) => item.title === crumb);
      menu = menuItem?.items || [];
    });
    setCurrentMenu(menu);
  };

  return (
    <div className="sidebar-menu">
      {breadcrumb.length > 0 && (
        <button className="back-button" onClick={handleBack}>
          &lt; Back
        </button>
      )}
      <ul className="menu-list">
        {currentMenu.map((menu, index) => (
          <li key={index} onClick={() => handleClick(menu)}>
            {menu.title}
            {menu.items && <span className="arrow">&gt;</span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarMenu;
