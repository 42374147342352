import React, { useState, useEffect } from 'react';
import categoryAll from '../../../../../../data/categoriesAll.json'; // Import your JSON file

interface Category {
  id: string;
  code: string;
  translatedName: string;
  parentCategories?: Category[] | null;
  childCategories?: Category[] | null;
}

const DynamicForm = () => {
  const [mainCategories, setMainCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState<string>('');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({}); // first return type any I changed this

  // Load main categories from JSON file
  useEffect(() => {
    setMainCategories(categoryAll); // Populate main categories
  }, []);

  // Handle main category selection
  const handleMainCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const mainCategoryId = e.target.value;
    setSelectedMainCategory(mainCategoryId);

    // Find subcategories for the selected main category
    const selectedCategory = mainCategories.find((category) => category.id === mainCategoryId);
    if (selectedCategory?.childCategories) {
      setSubCategories(selectedCategory.childCategories);
    } else {
      setSubCategories([]);
    }

    setSelectedSubCategory(''); // Reset subcategory selection
  };

  // Handle subcategory selection
  const handleSubCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubCategory(e.target.value);
  };

  // Handle input changes dynamically
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedMainCategory || !selectedSubCategory) {
      alert('Please select both main and subcategories.');
      return;
    }

    // Construct payload
    const payload = {
      primaryCategory: {
        id: selectedMainCategory,
      },
      adCategories: [
        {
          id: selectedSubCategory,
        },
      ],
      data: JSON.stringify(formValues), // Convert form values to a string
    };

    console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

    try {
      const response = await fetch('https://adzmonster.com/api/ads/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        setFormValues({}); // Reset form
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  return (
    <div>
      <h2>Post an Ad</h2>
      <form onSubmit={handleSubmit}>
        {/* Main Category Dropdown */}
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="mainCategory" style={{ fontWeight: 'bold' }}>
            Main Category:
          </label>
          <select
            id="mainCategory"
            value={selectedMainCategory}
            onChange={handleMainCategoryChange}
            style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
          >
            <option value="">Select Main Category</option>
            {mainCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.translatedName}
              </option>
            ))}
          </select>
        </div>

        {/* Subcategory Dropdown */}
        {subCategories.length > 0 && (
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="subCategory" style={{ fontWeight: 'bold' }}>
              Sub Category:
            </label>
            <select
              id="subCategory"
              value={selectedSubCategory}
              onChange={handleSubCategoryChange}
              style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
            >
              <option value="">Select Sub Category</option>
              {subCategories.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.translatedName}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Dynamic Form Fields */}
        {Object.keys(formValues).map((key) => (
          <div key={key} style={{ marginBottom: '10px' }}>
            <label htmlFor={key} style={{ fontWeight: 'bold' }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}:
            </label>
            <input
              id={key}
              name={key}
              type="text"
              value={formValues[key]}
              onChange={handleInputChange}
              placeholder={`Enter ${key}`}
              style={{ width: '100%', padding: '8px' }}
            />
          </div>
        ))}

        {/* Add More Fields Dynamically */}
        <div>
          <button
            type="button"
            onClick={() => setFormValues((prev) => ({ ...prev, newField: '' }))}
            style={{
              background: '#007BFF',
              color: '#FFF',
              padding: '8px 16px',
              border: 'none',
              borderRadius: '4px',
              marginRight: '10px',
              cursor: 'pointer',
            }}
          >
            Add Field
          </button>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          style={{
            background: '#28a745',
            color: '#FFF',
            padding: '10px 16px',
            border: 'none',
            borderRadius: '4px',
            marginTop: '10px',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default DynamicForm;
