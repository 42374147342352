import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LanguageSelector.css';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook

export interface Language {
  languageCode: string;
  translatedLanguageName: string;
}

const LanguageSelector = () => {
  const [languages, setLanguages] = useState<Language[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { selectedLanguage, setSelectedLanguage } = useLanguage(); // Access global language context

  useEffect(() => {
    const fetchLanguages = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://adzmonster.com/api/languages/supported');
        if (!response.ok) {
          setError(`HTTP error! Status: ${response.status}`);
          return;
        }
        const data: Language[] = await response.json();
        setLanguages(data);
        setError(null);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Failed to fetch languages');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setSelectedLanguage(event.target.value); // Update global context
    console.log(`Selected language: ${event.target.value}`);
  };

  if (isLoading) return <div className="text-center text-primary">Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="container">
      <div className="form-group" style={{ maxWidth: '150px' }}>
        <select
          id="languageDropdown"
          className="form-select"
          value={selectedLanguage}
          onChange={handleSelect}
          aria-label="Select language"
        >
          {languages.map((language) => (
            <option key={language.languageCode} value={language.languageCode}>
              {language.translatedLanguageName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LanguageSelector;
