import React, { useEffect, useState } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import './MainCategory.css';

interface Category {
  id: string;
  name: string;
  photo: string;
  totalAds: number;
}

const MainCategory: React.FC = () => {
  const [mainCategories, setMainCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:1234/categories');
        if (!response.ok) {
          console.error('Fetching error occurred');
        }
        const data: Category[] = await response.json();
        setMainCategories(data);
      } catch (error) {
        setError((error as Error).message);
      }
    };
    fetchData().catch(console.error);
  }, []);

  if (error) {
    return <div style={{ color: 'red' }}>Error: {error}</div>;
  }

  const COLUMN_WIDTH = 300; // Increased to accommodate spacing
  const ROW_HEIGHT = 300; // Increased to accommodate spacing
  const GRID_PADDING = 20; // Padding around the entire grid
  const CELL_SPACING = 24; // Space between cells
  // React Window Cell Renderer
  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
  }) => {
    const itemIndex = rowIndex * 6 + columnIndex;
    if (itemIndex >= mainCategories.length) return null;

    const item = mainCategories[itemIndex];

    // Calculate inner cell position with spacing
    const innerStyle: React.CSSProperties = {
      ...style,
      left: Number(style.left) + CELL_SPACING,
      top: Number(style.top) + CELL_SPACING,
      width: Number(style.width) - CELL_SPACING,
      height: Number(style.height) - CELL_SPACING,
      padding: '16px',
      position: 'absolute',
    };

    return (
      <div style={innerStyle} className="grid-cell">
        <img src={item.photo} alt={item.name} className="grid-image" />
        <h3 className="grid-title">{item.name}</h3>
        <p className="grid-ads">Total Ads: {item.totalAds}</p>
      </div>
    );
  };

  return (
    <div className="grid-container" style={{ padding: GRID_PADDING }}>
      <Grid
        columnCount={6}
        rowCount={Math.ceil(mainCategories.length / 6)}
        columnWidth={COLUMN_WIDTH}
        rowHeight={ROW_HEIGHT}
        width={COLUMN_WIDTH * 6 + GRID_PADDING * 2}
        height={800}
      >
        {Cell}
      </Grid>
    </div>
  );
};

export default MainCategory;

// import React, { useEffect, useState } from 'react';
// import { FixedSizeGrid as Grid } from 'react-window';
// interface Ad {
//   id: string;
//   primaryCategory: {
//     id: string;
//     code: string | null;
//   };
//   adCategories: {
//     id: string;
//     code: string;
//   }[];
//   data: string;
// }
//
// interface CategoryView {
//   id: string;
//   code: string;
//   ads: Ad[];
//   adCount: number;
// }
//
// const HoverArrowGrid: React.FC = () => {
//   const [categories, setCategories] = useState<Record<string, CategoryView>>({});
//   const [currentPage, setCurrentPage] = useState(0);
//   const [showArrows, setShowArrows] = useState(false);
//   const [currentAds, setCurrentAds] = useState<Ad[] | null>(null);
//   const [selectedCategoryCode, setSelectedCategoryCode] = useState<string>('');
//
//   const COLUMN_COUNT = 4;
//   const ROW_COUNT = 2;
//   const ITEMS_PER_PAGE = COLUMN_COUNT * ROW_COUNT;
//   const CELL_WIDTH = 300;
//   const CELL_HEIGHT = 320;
//
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await fetch('https://adzmonster.com/api/ads/all');
//         if (!response.ok) throw new Error('Failed to fetch categories');
//         const data: Ad[] = await response.json();
//
//         const groupedCategories = data.reduce<Record<string, CategoryView>>((acc, ad) => {
//           const primaryId = ad.primaryCategory.id;
//           const primaryCode =
//             ad.adCategories.find((cat) => cat.id === ad.primaryCategory.id)?.code || 'Unknown';
//
//           if (!acc[primaryId]) {
//             acc[primaryId] = {
//               id: primaryId,
//               code: primaryCode,
//               ads: [ad],
//               adCount: 1,
//             };
//           } else {
//             acc[primaryId].ads.push(ad);
//             acc[primaryId].adCount += 1;
//           }
//           return acc;
//         }, {});
//
//         setCategories(groupedCategories);
//       } catch (error) {
//         console.error('Error fetching categories:', error);
//       }
//     };
//
//     fetchCategories();
//   }, []);
//
//   const items = currentAds || Object.values(categories);
//   const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);
//
//   const handleNavigation = (direction: 'up' | 'down' | 'left' | 'right') => {
//     switch (direction) {
//       case 'up':
//         setCurrentPage((prev) => Math.max(0, prev - COLUMN_COUNT));
//         break;
//       case 'down':
//         setCurrentPage((prev) => Math.min(totalPages - 1, prev + COLUMN_COUNT));
//         break;
//       case 'left':
//         setCurrentPage((prev) => Math.max(0, prev - 1));
//         break;
//       case 'right':
//         setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
//         break;
//     }
//   };
//
//   const Cell: React.FC<{
//     columnIndex: number;
//     rowIndex: number;
//     style: React.CSSProperties;
//   }> = ({ columnIndex, rowIndex, style }) => {
//     const itemIndex = currentPage * ITEMS_PER_PAGE + (rowIndex * COLUMN_COUNT + columnIndex);
//     if (itemIndex >= items.length) return null;
//
//     const item = items[itemIndex];
//
//     if (currentAds) {
//       const ad = item as Ad;
//       let adData;
//       try {
//         adData = JSON.parse(ad.data);
//       } catch (error) {
//         console.error('Failed to parse ad data:', error);
//         adData = {};
//       }
//
//       return (
//         <div style={style}>
//           <div className="m-2 p-4 bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow">
//             <h3 className="text-lg font-semibold">{adData.listingTitle || 'No Title'}</h3>
//             <p className="text-sm text-gray-600 truncate">
//               {adData.description || 'No Description'}
//             </p>
//             <p className="mt-2">Price: ${adData.price?.toFixed(2) || 'N/A'}</p>
//             {adData.location && (
//               <p className="text-sm text-gray-600">Location: {adData.location}</p>
//             )}
//           </div>
//         </div>
//       );
//     } else {
//       const category = item as CategoryView;
//       return (
//         <div style={style}>
//           <div
//             className="m-2 p-4 bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow cursor-pointer"
//             onClick={() => {
//               setCurrentAds(category.ads);
//               setSelectedCategoryCode(category.code);
//               setCurrentPage(0);
//             }}
//           >
//             <h3 className="text-lg font-semibold">{category.code}</h3>
//             <p className="text-sm text-gray-600">Total Ads: {category.adCount}</p>
//           </div>
//         </div>
//       );
//     }
//   };
//
//   return (
//     <div className="p-4">
//       <div className="mb-4 flex justify-between items-center">
//         <h1 className="text-2xl font-bold">
//           {currentAds ? `${selectedCategoryCode} Ads` : 'Categories'}
//         </h1>
//         {currentAds && (
//           <button
//             onClick={() => {
//               setCurrentAds(null);
//               setSelectedCategoryCode('');
//               setCurrentPage(0);
//             }}
//             className="px-4 py-2 bg-blue-500 text-black rounded hover:bg-blue-600"
//           >
//             Back to Categories
//           </button>
//         )}
//       </div>
//
//       <div
//         className="relative"
//         onMouseEnter={() => setShowArrows(true)}
//         onMouseLeave={() => setShowArrows(false)}
//       >
//         <div className="overflow-hidden">
//           <Grid
//             columnCount={COLUMN_COUNT}
//             columnWidth={CELL_WIDTH}
//             height={CELL_HEIGHT * ROW_COUNT}
//             rowCount={ROW_COUNT}
//             rowHeight={CELL_HEIGHT}
//             width={CELL_WIDTH * COLUMN_COUNT}
//             style={{ overflow: 'hidden' }}
//           >
//             {Cell}
//           </Grid>
//         </div>
//
//         {showArrows && (
//           <>
//             {currentPage >= COLUMN_COUNT && (
//               <button
//                 onClick={() => handleNavigation('up')}
//                 className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-all"
//               >
//                 <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M5 15l7-7 7 7"
//                   />
//                 </svg>
//               </button>
//             )}
//
//             {currentPage < totalPages - COLUMN_COUNT && (
//               <button
//                 onClick={() => handleNavigation('down')}
//                 className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-8 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-all"
//               >
//                 <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M19 9l-7 7-7-7"
//                   />
//                 </svg>
//               </button>
//             )}
//
//             {currentPage > 0 && (
//               <button
//                 onClick={() => handleNavigation('left')}
//                 className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-8 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-all"
//               >
//                 <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M15 19l-7-7 7-7"
//                   />
//                 </svg>
//                 ←
//               </button>
//             )}
//
//             {currentPage < totalPages - 1 && (
//               <button
//                 onClick={() => handleNavigation('right')}
//                 className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-8 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-all"
//               >
//                 <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M9 5l7 7-7 7"
//                   />
//                 </svg>
//                 →
//               </button>
//             )}
//           </>
//         )}
//       </div>
//     </div>
//   );
// };
//
// export default HoverArrowGrid;

//   // React Window Cell Renderer
//   const Cell = ({
//     columnIndex,
//     rowIndex,
//     style,
//   }: {
//     columnIndex: number;
//     rowIndex: number;
//     style: React.CSSProperties;
//   }) => {
//     const itemIndex = rowIndex * 6 + columnIndex; // Adjust based on the number of columns
//     if (itemIndex >= mainCategories.length) return null;
//
//     const item = mainCategories[itemIndex];
//     return (
//       <div
//         style={{
//           ...style,
//           padding: '16px',
//           border: '1px solid #ccc',
//         }}
//         className="grid-cell"
//       >
//         <img src={item.photo} alt={item.name} className="grid-image" />
//         <h3 className="grid-title">{item.name}</h3>
//         <p className="grid-ads">Total Ads: {item.totalAds}</p>
//       </div>
//     );
//   };
//
//   return (
//     <div className="grid-container">
//       <Grid
//         columnCount={6} // Number of columns
//         rowCount={Math.ceil(mainCategories.length / 6)} // Adjust based on columnCount
//         columnWidth={250} // Adjust width of each column
//         rowHeight={250} // Adjust height of each row
//         width={1500} // Width of the entire grid
//         height={600} // Height of the grid container
//       >
//         {Cell}
//       </Grid>
//     </div>
//   );
// };
//
// export default MainCategory;
//
// import { useEffect, useState } from 'react';
// import './MainCategory.css';
// interface Category {
//   id: string;
//   name: string;
//   photo: string;
//   totalAds: number;
// }
//
// const MainCategory = () => {
//   const [mainCategories, setMainCategories] = useState<Category[]>([]);
//   const [error, setError] = useState<string | null>(null);
//
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://localhost:1234/categories');
//         if (!response.ok) {
//           throw new Error('Fetching error occurred');
//         }
//         const data: Category[] = await response.json();
//         setMainCategories(data);
//       } catch (error) {
//         setError((error as Error).message);
//       }
//     };
//     fetchData().catch(console.error);
//   }, []);
//
//   if (error) {
//     return <div style={{ color: 'red' }}>Error: {error}</div>;
//   }
//
//   return (
//     <div
//       className="row "
//       id="maincategory"
//       style={{ color: 'white', marginTop: '300px', zIndex: '10' }}
//     >
//       {mainCategories.map((item) => (
//         <div key={item.id} className="col">
//           <img src={item.photo} alt={item.name} className="grid-image" />
//           <h3 className="grid-title">{item.name}</h3>
//           <p className="grid-ads">Total Ads: {item.totalAds}</p>
//         </div>
//       ))}
//     </div>
//   );
// };
//
// export default MainCategory;
