import React, { useEffect, useState } from 'react';
import { useLanguage } from '@features/auth/components/Header/LanguageSelector/LanguageContext.tsx';

interface Category {
  id: string;
  code: string;
  translatedName: string; // Add this for translations
}

const Menu = () => {
  const { selectedLanguage } = useLanguage(); // Get current language
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [translations] = useState({
    all: 'All',
    loading: 'Loading categories...',
  });

  // Fetch categories with translations
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `https://adzmonster.com/api/category/allTranslated?languageCode=${selectedLanguage}`,
        );
        if (!response.ok) {
          console.log(`${response.status}`);
        }
        const data: Category[] = await response.json();
        setCategories(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories().catch(console.error);
  }, [selectedLanguage]); // Re-fetch when language changes

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
    console.log('Selected category code:', e.target.value);
  };

  if (isLoading) {
    return <div>{translations.loading}</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>Error: {error}</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <select
        value={selectedCategory}
        onChange={handleSelect}
        className="form-select"
        style={{
          display: 'block',
          width: '100px',
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
      >
        <option value="" disabled>
          {translations.all}
        </option>
        {categories.map((category) => (
          <option key={category.id} value={category.code}>
            {category.translatedName || category.code}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Menu;
