import { useState, useEffect } from 'react';
import './Categories.css';
import { useLanguage } from '../LanguageSelector/LanguageContext.tsx';

export interface Category {
  id: string;
  code: string;
  translatedName: string;
  languageCode: string;
  parentCategories: Category[] | null;
  childCategories: Category[] | null;
  relatedCategories: Category[] | null;
}

const Categories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [visibleCategories, setVisibleCategories] = useState<Category[]>([]);
  const { selectedLanguage } = useLanguage(); // Default language is English
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showAllCategories, setShowAllCategories] = useState(false);

  const CATEGORY_LIMIT = 50; // Limit the number of categories initially displayed

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://adzmonster.com/api/category/allTranslated?languageCode=${selectedLanguage}`,
        );
        if (!response.ok) {
          console.error(`HTTP error! Status: ${response.status}`);
          //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data: Category[] = await response.json();
        setCategories(data);
        setVisibleCategories(data.slice(0, CATEGORY_LIMIT)); // Initially limit categories
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Failed to fetch categories');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories().catch(console.error);
  }, [selectedLanguage]);

  const handleShowAll = () => {
    setShowAllCategories((prev) => !prev);
    setVisibleCategories(showAllCategories ? categories.slice(0, CATEGORY_LIMIT) : categories);
  };

  if (isLoading) {
    return <div className="text-center text-primary">Loading categories...</div>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="container">
      {/* Categories List */}
      <div className="categories-container row">
        {visibleCategories.map((category) => (
          <div key={category.id} className="col-lg-3 col-md-4 ">
            <h6 className="category-name text-primary">{category.translatedName}</h6>
            {category.childCategories && category.childCategories.length > 0 ? (
              <ul className="list-unstyled">
                {category.childCategories.map((childCategory) => (
                  <li key={childCategory.id} className="subcategory-item text-secondary">
                    {childCategory.translatedName}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-secondary">{/*No subcategories*/}</p>
            )}
          </div>
        ))}
      </div>

      {/* Show All Categories Button */}
      <div className="text-center mt-4">
        <button className="btn btn-primary" onClick={() => handleShowAll}>
          {showAllCategories ? 'Show Less' : 'See All Categories'}
        </button>
      </div>
    </div>
  );
};

export default Categories;
