import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';

const LOGIN_API_URL = 'https://adzmonster.com/api/users/login';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.redirectTo || '/v1';

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(LOGIN_API_URL, null, {
        params: { username, password },
      });
      if (response.status === 200) {
        setSuccessMessage('Login successful!');
        setTimeout(() => {
          navigate(redirectTo, { state: { isLoggedIn: true, username } });
        }, 500);
      } else {
        setError('Invalid username or password.');
      }
    } catch (err) {
      setError('Error connecting to the server. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="card shadow-lg p-4 d-flex flex-row" style={{ width: '800px' }}>
        <div className="left-panel p-4 flex-grow-1" style={{ borderRight: '1px solid #ddd' }}>
          <h2 className="text-center mb-4">Sign In</h2>

          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}

          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                type="text"
                id="username"
                className="form-control"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? 'Processing...' : 'Sign In'}
            </button>
          </form>
        </div>

        <div className="right-panel p-4 flex-grow-1 d-flex flex-column align-items-center justify-content-center">
          <h3>New Here?</h3>
          <p>Create an account to explore our features!</p>
          <Link to="/register" className="btn btn-outline-primary">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
