import React, { useEffect, useState } from 'react';
import { fetchLogo } from '../../../api/logo.api.ts';
import type { LogoProps } from '../../../types/logo.types.ts';
import { useNavigate } from 'react-router-dom';
const Logo: React.FC<LogoProps> = ({ altText, className }) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const loadLogo = async () => {
      try {
        const logo = await fetchLogo();
        setLogoUrl(logo.url);
      } catch (error) {
        console.error('Failed to fetch logo:', error);
      }
    };
    loadLogo().catch(console.error);
  }, []);

  const handleClick = () => {
    navigate('/v1');
  };

  return (
    <div className={className}>
      {logoUrl ? (
        <img
          src={logoUrl}
          alt={altText}
          style={{ height: '50px', width: '50px' }}
          onClick={handleClick}
        />
      ) : (
        <span>Loading...</span>
      )}
    </div>
  );
};

export default Logo;
