import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/features/auth/components/Home/Home.tsx';
import LoginForm from '@features/auth/components/Header/LoginForm/LoginForm.tsx';
import RegistrationForm from '@features/auth/components/Header/RegistrationForm/registrationform.tsx';

import './App.css';
import SidebarMenu from './features/auth/components/Header/Menu/SidebarMenu.tsx';
import DataDisplay from '@features/auth/components/Header/PostAd/DataDisplay.tsx';
// import DynamicForm from '@features/auth/components/Header/PostAd/DynamicForm.tsx';

export default function App() {
  return (
    <>
      {/*<Header />*/}
      <Router>
        <Routes>
          <Route path="/v1" element={<Home />} />
          {/*<Route path="/v2" element={<Home2 />} />*/}
          <Route path="/login" element={<LoginForm />} />
          <Route path="/Register" element={<RegistrationForm />} />
          <Route path="/v2" element={<DataDisplay />} />

          <Route path="/v3" element={<SidebarMenu />} />
          {/*<Route path="/v4" element={<DynamicForm />} />*/}
        </Routes>
      </Router>
    </>
  );
}
