import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import api from 'axios';

const SIGNUP_API_URL = 'https://adzmonster.com/api/users/create';

const Register: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetPassword, setResetPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.redirectTo || '/v1';

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignUp = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!validateEmail(email)) {
      setError('Invalid email format.');
      setLoading(false);
      return;
    }

    if (password !== resetPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    const payload = {
      username,
      password,
      userRole: 'ADMIN',
      emailContacts: [{ priority: 1, email }],
      phoneContacts: [{ priority: 1, phone }],
    };
    console.log('Payload being sent:', JSON.stringify(payload, null, 2));
    try {
      const response = await api.post(SIGNUP_API_URL, payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.status === 200) {
        setSuccessMessage('Registration successful!');
        setTimeout(() => {
          navigate(redirectTo, { state: { isLoggedIn: true } });
        }, 1000);
      } else {
        setError('Registration failed. Please try again.');
      }
    } catch (err) {
      setError('Error connecting to the server. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="card shadow-lg p-4 d-flex flex-row" style={{ width: '800px' }}>
        <div className="left-panel p-4 flex-grow-1" style={{ borderRight: '1px solid #ddd' }}>
          <h2 className="text-center mb-4">Sign Up</h2>

          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}

          <form onSubmit={handleSignUp}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                type="text"
                id="username"
                className="form-control"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="resetPassword" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                id="resetPassword"
                className="form-control"
                value={resetPassword}
                onChange={(e) => setResetPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? 'Processing...' : 'Sign Up'}
            </button>
          </form>
        </div>

        <div className="right-panel p-4 flex-grow-1 d-flex flex-column align-items-center justify-content-center">
          <h3>Welcome Back!</h3>
          <p>Already have an account? Sign in now!</p>
          <Link to="/login" className="btn btn-outline-primary">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
