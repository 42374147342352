import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Category {
  id: number;
  name: string;
  subcategories: Subcategory[];
}

interface Subcategory {
  name: string;
  products?: string[];
}

const DropdownMenu = () => {
  const navigate = useNavigate();

  const categories: Category[] = [
    {
      id: 1,
      name: 'Trending',
      subcategories: [
        { name: 'Best Sellers' },
        { name: 'New Releases' },
        { name: 'Movers and Shakers' },
      ],
    },
    {
      id: 2,
      name: 'Digital Content and Devices',
      subcategories: [
        { name: 'Amazon miniTV - FREE entertainment' },
        { name: 'Echo & Alexa' },
        { name: 'Fire TV' },
        { name: 'Kindle E-Readers & eBooks' },
        { name: 'Audible Audiobooks' },
        { name: 'Amazon Prime Video' },
        { name: 'Amazon Prime Music' },
      ],
    },
    {
      id: 3,
      name: 'Shop by Category',
      subcategories: [
        {
          name: 'Mobiles',
          products: ['Display Protectors', 'Cases', 'Powerbanks'],
        },
        {
          name: 'Computers',
          products: ['Laptops', 'Keyboards', 'Monitors'],
        },
        {
          name: 'Fashion',
          products: ['Clothing', 'Footwear', 'Accessories'],
        },
      ],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<Subcategory | null>(null);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
  };

  const handleSubcategoryClick = (subcategory: Subcategory) => {
    setSelectedSubcategory(subcategory);
    if (!subcategory.products) {
      navigate(`/products/${subcategory.name.toLowerCase().replace(/\s+/g, '-')}`);
    }
  };

  const handleProductClick = (product: string) => {
    navigate(`/products/${product.toLowerCase().replace(/\s+/g, '-')}`);
  };

  return (
    <div className="dropdown-menu">
      <ul>
        {categories.map((category) => (
          <li key={category.id} onClick={() => handleCategoryClick(category)}>
            {category.name}
          </li>
        ))}
      </ul>

      {selectedCategory && (
        <div className="submenu">
          <h4>{selectedCategory.name}</h4>
          <ul>
            {selectedCategory.subcategories.map((subcategory, index) => (
              <li key={index} onClick={() => handleSubcategoryClick(subcategory)}>
                {subcategory.name}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedSubcategory && selectedSubcategory.products && (
        <div className="submenu">
          <h4>{selectedSubcategory.name}</h4>
          <ul>
            {selectedSubcategory.products.map((product, index) => (
              <li key={index} onClick={() => handleProductClick(product)}>
                {product}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
