import React from 'react';
import ReactDOM from 'react-dom/client';
// import { componentRegistry } from './demo/registry/ComponentRegistry';

import App from '@/App.tsx';

// // Register components
// componentRegistry.register('login-form', {
//   title: 'Login Form',
//   path: '/login-form',
//   parameters: {
//     docs: {
//       description: 'Demo for the Login Form component',
//     },
//   },
//   argTypes: {
//     onSuccess: { action: 'onSuccess' },
//     onError: { action: 'onError' },
//   },
// });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
