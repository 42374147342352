import './SidebarMenu.css';

export type MenuItem = {
  title: string;
  items?: MenuItem[];
  link?: string;
};

export const menuData: MenuItem[] = [
  {
    title: 'Electronics',
    items: [
      {
        title: 'Mobile Phones',
        link: '/electronics/mobile-phones',
      },
      {
        title: 'Laptops',
        items: [
          {
            title: 'Gaming Laptops',
            link: '/electronics/laptops/gaming',
          },
          {
            title: 'Business Laptops',
            link: '/electronics/laptops/business',
          },
        ],
      },
      {
        title: 'Cameras',
        link: '/electronics/cameras',
      },
    ],
  },
  {
    title: 'Home Appliances',
    items: [
      {
        title: 'Refrigerators',
        link: '/home-appliances/refrigerators',
      },
      {
        title: 'Washing Machines',
        link: '/home-appliances/washing-machines',
      },
    ],
  },
  {
    title: 'Fashion',
    items: [
      {
        title: "Men's Clothing",
        link: '/fashion/mens-clothing',
      },
      {
        title: "Women's Clothing",
        link: '/fashion/womens-clothing',
      },
      {
        title: 'Accessories',
        link: '/fashion/accessories',
      },
    ],
  },
  {
    title: 'Books',
    link: '/books',
  },
  {
    title: 'Sports',
    link: '/sports',
  },
];
