import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

// Define the type for the context value
interface LanguageContextType {
  selectedLanguage: string;
  setSelectedLanguage: (languageCode: string) => void;
}

// Create the context
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Create a provider component
export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('EN'); // Default language is English

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook to use the context
// eslint-disable-next-line react-refresh/only-export-components
export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    // throw new Error('useLanguage must be used within a LanguageProvider');
    console.log('useLanguage must be used within a LanguageProvider');
  }
  return context as LanguageContextType;
};
