import React, { useState, useEffect } from 'react';
import './HeaderBelow.css';
import { useLanguage } from '@features/auth/components/Header/LanguageSelector/LanguageContext.tsx';
import { ChevronRight, ChevronLeft } from 'lucide-react';

interface Category {
  id: string;
  code: string;
  translatedName: string;
  childCategories?: Category[];
}

const HeaderBelow: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentCategories, setCurrentCategories] = useState<Category[]>([]);
  const [categoryStack, setCategoryStack] = useState<Category[][]>([]);
  const [translations] = useState({
    all: 'All',
    loading: 'Loading categories...',
    back: 'Back',
  });
  const { selectedLanguage } = useLanguage();

  // Fetch categories with translations
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `https://adzmonster.com/api/category/allTranslated?languageCode=${selectedLanguage}`,
        );
        if (!response.ok) {
          console.log(`${response.status}`);
        }
        const data: Category[] = await response.json();
        setCategories(data);
        setCurrentCategories(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories().catch(console.error);
  }, [selectedLanguage]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Reset to root categories when closing menu
    if (!isMenuOpen) {
      setCurrentCategories(categories);
      setCategoryStack([]);
    }
  };

  const handleCategoryClick = (category: Category) => {
    if (category.childCategories && category.childCategories.length > 0) {
      setCategoryStack([...categoryStack, currentCategories]);
      setCurrentCategories(category.childCategories);
    }
  };

  const handleBack = () => {
    if (categoryStack.length > 0) {
      const previousCategories = categoryStack[categoryStack.length - 1];
      const newStack = categoryStack.slice(0, -1);
      setCurrentCategories(previousCategories);
      setCategoryStack(newStack);
    }
  };

  if (isLoading) {
    return <div>{translations.loading}</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>Error: {error}</div>;
  }

  return (
    <div>
      <button className="menu-button" onClick={toggleMenu}>
        Open Menu
      </button>

      {isMenuOpen && (
        <div className="menu-overlay">
          <div className="menu-popup">
            <div className="menu-header">
              {categoryStack.length > 0 && (
                <button
                  className="back-button flex bg-light items-center gap-2"
                  onClick={handleBack}
                >
                  <ChevronLeft className="w-4 h-4" />
                  <span>{translations.back}</span>
                </button>
              )}
              <button
                className="close-button flex items-center justify-between"
                onClick={toggleMenu}
              >
                &times;
              </button>
            </div>
            <h2>Categories</h2>
            {isLoading && <p>Loading categories...</p>}
            {error && <p className="error">{error}</p>}
            {!isLoading && !error && currentCategories.length === 0 && <p>No categories found</p>}
            <ul className="categories-list">
              {currentCategories.map((category) => (
                <li
                  key={category.id}
                  className="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleCategoryClick(category)}
                >
                  <span>{category.translatedName}</span>
                  {category.childCategories && category.childCategories.length > 0 && (
                    <ChevronRight className="w-4 h-4" />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderBelow;
