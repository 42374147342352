import Header from '@features/auth/components/Header/Header and routes/Header.tsx';
import ConsentBanner from '@features/auth/components/ConsentBanner/ConsentBanner.tsx';
import { LanguageProvider } from '@features/auth/components/Header/LanguageSelector/LanguageContext.tsx';
import HeaderBelow from '@features/auth/components/Header/HeaderBelow/HeaderBelow.tsx';
import Footer from '@features/auth/components/Footer/Footer.tsx';
import MainCategory from '@features/auth/components/Main Content/MainCategory.tsx';
import './Home.css';
import DropdownMenu from '@features/auth/components/Header/Dropdown categories/DropdownMenu.tsx';
const Home = () => {
  return (
    <div>
      <ConsentBanner />
      <LanguageProvider>
        <Header />

        <HeaderBelow />

        <DropdownMenu />
        <div className="background-container">
          {/* Background image */}
          <img
            src="https://images.pexels.com/photos/29647437/pexels-photo-29647437/free-photo-of-serene-mountain-lake-in-norwegian-fjord.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="Background"
            className="background-image"
          />
          {/* Main Category Overlay */}
          <div className="overlay">
            <MainCategory />
          </div>
        </div>
        <Footer />
      </LanguageProvider>
    </div>
  );
};

export default Home;
