import React, { useState } from 'react';

const ConsentBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true); // Always show the banner for now

  const handleAccept = () => {
    setIsVisible(false);
    // Future: Add localStorage logic here if needed
    // localStorage.setItem('userConsent', 'true');
  };

  const handleDecline = () => {
    window.location.href = 'https://example.com/goodbye'; // Redirect users who decline
  };

  if (!isVisible) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.banner}>
        <p style={styles.text}>
          To ensure security, prevent fraud, verify ad authenticity, and protect against cyber
          threats, this site requires access to your IP address and location. By continuing, you
          agree to this policy. If you do not agree, you may exit.
        </p>
        <div style={styles.buttonContainer}>
          <button style={styles.acceptButton} onClick={handleAccept}>
            Agree and Continue
          </button>
          <button style={styles.declineButton} onClick={handleDecline}>
            Exit Site
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it's above everything
  },
  banner: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center' as const,
    maxWidth: '400px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  },
  text: {
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  acceptButton: {
    padding: '10px 20px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    flex: 1,
  },
  declineButton: {
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    flex: 1,
  },
};

export default ConsentBanner;
