import React, { useState } from 'react';
import './LocationSelector.css';

interface Location {
  city: string;
  pincode: string;
}

export default function LocationSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState<Location>({
    city: 'Hyderabad',
    pincode: '500028',
  });
  const [pincode, setPincode] = useState('');

  const handlePincodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (pincode.length === 6) {
      setLocation({
        city: 'New Location', // This would typically come from an API
        pincode: pincode,
      });
      setIsOpen(false);
      setPincode('');
    }
  };

  return (
    <div className="location-selector">
      <button
        className="location-button"
        onClick={() => setIsOpen(!isOpen)} // Toggle isOpen state on click
      >
        🏠︎ {location.city} {location.pincode}
      </button>

      {isOpen && (
        <div className="dialog-overlay" style={{ zIndex: '100' }}>
          <div className="dialog-content">
            <div className="dialog-header">
              <h2>Choose your location</h2>
              <button className="close-button" onClick={() => setIsOpen(false)}>
                ✖
              </button>
            </div>
            <p className="dialog-description">
              Select a delivery location to see product availability and delivery options
            </p>

            <button className="signin-button">Sign in to see your addresses</button>

            <div className="separator">
              <span className="separator-text">or enter an Indian pincode</span>
            </div>

            <form onSubmit={handlePincodeSubmit} className="pincode-form">
              <input
                type="text"
                placeholder="Enter pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value.slice(0, 6))}
                pattern="[0-9]{6}"
                maxLength={6}
                className="pincode-input"
              />
              <button type="submit" className="apply-button">
                Apply
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
