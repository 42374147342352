import React, { useEffect, useRef, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import Categories from '@features/auth/components/Header/Dropdown categories/Dropdowncategoryies.tsx';
import './Searchbar.css';
import Menu from '@features/auth/components/Header/Menu.tsx';

const Searchbar = () => {
  const [search, setSearch] = useState('');
  const [isDropDown, setIsDropDown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Handles form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (search.trim() === '') {
      console.log('Please enter a search term.');
    } else {
      console.log('Search:', search);
    }
  };

  // Handles search input change
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsDropDown(false);
    }
  };

  // Toggles the dropdown visibility
  const handleDropDown = () => {
    setIsDropDown((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mouseleave', handleClickOutside);
    };
  }, []);

  return (
    <div className="searchbar-container">
      <div className="categories-wrapper">
        <button type="button" className="categories-button" onClick={handleDropDown}>
          Categories
        </button>

        {isDropDown && (
          <div ref={dropdownRef} className="categories-dropdown">
            <Categories />
          </div>
        )}
      </div>
      <div className="search-form" onSubmit={handleSubmit}>
        <div className="search-input-wrapper">
          <input
            type="text"
            className="search-input"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
          />

          <Menu />
        </div>
        <button type="submit" className="search-button">
          <FaSearch />
        </button>
      </div>
    </div>
  );
};

export default Searchbar;
